<template>
	<div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-12 col-sm-8 col-md-10 px-1 mb-3">
                     <label><i class="far fa-filter color-theme me-1"></i> {{ $t('admLogs.filtro') }}</label>
                     <v-select title="Desktop" :clearable="false" :options="$t('admLogs.filtros')" v-model="pesquisa.tipo" label="value" :reduce="f => f.key" />
                     <select title="Mobile" class="form-control" v-model="pesquisa.tipo">
                        <option v-for="(option, index) in $t('admLogs.filtros')" :key="index" :value="option.key">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-12 col-sm-4 col-md-2 px-1 mb-3 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="searchHistorico(0)">
                        <i class="fas fa-search font-12"></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
      
      <div class="col-12" v-if="pesquisa.resultado.length > 0">
         <log v-for="(log, index) in pesquisa.resultado" :key="index" :log="log" :index="index" />
      </div>
      <div class="col-12 my-5 text-center" v-else>{{ $t('admLogs.semResultados') }}</div>

      <div class="col-12">
         <div class="d-flex justify-content-center my-4" v-if="totalPagina == 50">
            <button class="btn btn-primary rounded" title="Carregar mais logs" @click="searchHistorico(null)">{{ $t('admLogs.verMais') }}</button>
         </div>
      </div>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import log from '@/components/admin/logs/Log.vue'

export default {
	name: 'Estatísticas',
	data : function () {
		return {
         pesquisa: {'nome': '', 'tipo': 'Aula visualizada', 'resultado': []},
         pagina: 0,
         totalPagina: 0
		}
	},
	components: {
      log
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			urlRest: state => state.urlRest
      })
   },
   watch: {
      'pesquisa.tipo': function () {
         this.searchHistorico(0)
      },
   },
	methods: {
		searchHistorico : function (pagina) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pagina = pagina == null ? ++this.pagina : pagina

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchHistorico',
				params: {
					pagina: this.pagina,
               tipo: this.pesquisa.tipo
				}
         }).then(response => {
            if (this.pagina == 0) {
               this.pesquisa.resultado = []
            }

            this.totalPagina = response.data.length
            response.data.forEach(p => this.pesquisa.resultado.push(p));

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
	},
	mounted() {
		this.searchHistorico(0)
	}
}

</script>